<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:35:38
 * @LastEditTime: 2021-09-13 17:02:07
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\index.vue
-->
<template>
  <el-container class="page-index">
    <el-header>
      <public-header></public-header>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>
<script>
import publicHeader from '../components/publicHeader.vue'
export default {
  components: {
    publicHeader,
  },
}
</script>
<style lang="scss" scoped>
.page-index {
  .content {
    height: calc(100vh - 64px);
    min-width: 1200px;
    display: flex;
    .router {
      flex: 1;
      height: 100%;
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>