<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:47:45
 * @LastEditTime: 2021-09-10 10:24:46
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\publicHeader.vue
-->
<template>
  <div class="header">
    <div class="header-left">
      <img src="../assets/images/logo.png" />
    </div>
    <div class="header-middle">
      <el-menu
        :default-active="asideActive"
        :router="true"
        mode="horizontal"
        active-text-color="#4e9c95"
      >
        <!-- <el-menu-item index="home">
          <span slot="title">试题总览</span>
        </el-menu-item>-->
        <el-submenu index="2">
          <template slot="title">
            <span>试题列表</span>
          </template>
          <el-menu-item index="questionBank?grade=2&subject=2">高中数学题库</el-menu-item>
          <el-menu-item index="questionBank?grade=1&subject=2">初中数学题库</el-menu-item>
        </el-submenu>
        <el-menu-item index="addNewQuestion">
          <span slot="title">新题录入</span>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title">
            <span>知识图谱</span>
          </template>
          <el-submenu index="5">
            <template slot="title">
              <span>教材录入</span>
            </template>
            <el-menu-item index="knowledgePoint?type=textbook&grade=2&subject=2">高中数学</el-menu-item>
            <el-menu-item index="knowledgePoint?type=textbook&grade=1&subject=2">初中数学</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <span>CAT知识图谱</span>
            </template>
            <el-menu-item index="knowledgeGraph?type=cat&grade=2&subject=2">高中数学</el-menu-item>
            <el-menu-item index="knowledgeGraph?type=cat&grade=1&subject=2">初中数学</el-menu-item>
          </el-submenu>
          <el-menu-item index="videoGraph">媒资图谱</el-menu-item>
        </el-submenu>
        <el-menu-item index="videoBank">媒资数据库</el-menu-item>
      </el-menu>
    </div>
    <div class="header-right">
      <el-avatar icon="el-icon-user-solid" :size="24"></el-avatar>
      <span class="name">{{userName}}</span>
      <el-button type="text" @click="signOut">注销</el-button>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    userName() {
      return localStorage.getItem('userName')
    },
    asideActive() {
      const fullPath = this.$route.fullPath
      console.log(fullPath.substring(1, fullPath.length))
      return fullPath.substring(1, fullPath.length)
    },
  },
  methods: {
    signOut() {
      localStorage.removeItem('token')
      localStorage.removeItem('userName')
      localStorage.removeItem('userType')
      this.$router.replace({
        name: 'Login',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  line-height: 64px;
  background-color: #fff;
  font-size: 0;
  &-left {
    width: 360px;
    height: 38px;
    padding: 0 28px;
    font-size: 0;
    box-sizing: border-box;
    img {
      width: 200px;
      height: 38px;
    }
  }
  &-middle {
    flex: 1;
    padding: 0 32px;
    ::v-deep .el-menu {
      border: none;
      font-family: PingFangSC-Normal;
      .el-menu-item {
        &:hover {
          background-color: #4e9c95;
          color: #fff !important;
        }
      }
      .el-submenu__title {
        .el-submenu__icon-arrow {
          display: none;
        }
        &:hover {
          background-color: #4e9c95;
          color: #fff !important;
        }
      }
      .el-menu-item.is-active {
        border-bottom: none;
      }
    }
  }
  &-right {
    display: flex;
    align-items: center;
    padding: 0 28px;
    .name {
      padding-left: 8px;
      padding-right: 32px;
      font-size: 14px;
    }
  }
}
</style>
